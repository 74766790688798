import React from "react";
import {getPluginSdk} from "./currentSdk";
import {useColorMode} from "../../hooks/useColorMode"

type PluginStartupRunnerProps = {
  children: React.ReactNode;
};

// Use the defined props
const PluginStartupRunner: React.FC<PluginStartupRunnerProps> = ({ children }) => {

  const pluginSdk = getPluginSdk();

  if (pluginSdk.onStartup) {
    pluginSdk.onStartup();
  }

	useColorMode()

  return children;
}

export default PluginStartupRunner;
