import React, {ReactNode} from "react";
import {getPluginSdk} from "./currentSdk";


const GetReportScript: React.FC = () => {
  let pluginSdk = getPluginSdk();

  const StartupComponent = pluginSdk.startupComponent;

  return (<>
      <script src={pluginSdk.getScript()} data-options="sizeToParent:true;"/>
      {StartupComponent ? <StartupComponent /> : null}
    </>
  );
}

export default GetReportScript;
