import React from 'react';
import { errorMessageGeneral, errorMessageLinkText } from "@getreport/next/localization";
import { Box, styled, Typography } from '@mui/joy';
import { PluginError } from "@getreport/common";
import Image from "next/image";
import { useGetReportPluginContext } from "@getreport/next";
import Link from "next/link";

// Styled component for image container
const ImageContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100px;
`;

// Styled component for centering content
const CenteredBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
  gap: 1em;
`;

export interface ErrorWidgetProps {
  error?: PluginError
}

const ErrorMessage: React.FC<ErrorWidgetProps> = ({ error }) => {
  const message = error?.message || errorMessageGeneral;
  const pluginInfo = useGetReportPluginContext().pluginInfo;
  const link = error?.link || pluginInfo.helpLink;

  return (
    <CenteredBox>
      <ImageContainer>
        <Image src={pluginInfo.logoSquareUrl} alt="Logo" layout="fill" objectFit="contain" />
      </ImageContainer>
      <Typography component="div">
        {message}
        <div>{link && <Link href={link.url} target={link.target || "_blank"}>
          {link.title || errorMessageLinkText}
        </Link>}</div>
      </Typography>

    </CenteredBox>
  );
}

export default ErrorMessage;
