

export async function serverPostCall<T, R>(path: string, extraHeaders: HeadersInit | null, body: T): Promise<R | null> {
  let headersInit: HeadersInit = {
    'Content-Type': 'application/json'
  }
  // append headers
  if (extraHeaders) {
    headersInit = {
      ...headersInit,
      ...extraHeaders
    }
  }

  const response = await fetch(path, {
    method: 'POST',
    headers: headersInit,
    body: JSON.stringify(body),
  });

  // If the status is 401 (Unauthorized), return null
  if (response.status === 401) {
    console.warn('Unauthorized', path);
    return null;
  }

  // If the response is not ok and it's not 401, throw an error
  if (!response.ok) {
    throw new Error('Network response was not ok: ' + path);
  }

  return await response.json();
}

export async function serverGetCall<R>(path: string, extraHeaders: HeadersInit | null): Promise<R | null> {
	let headersInit: HeadersInit = {
		'Content-Type': 'application/json'
	}
	// append headers
	if (extraHeaders) {
		headersInit = {
			...headersInit,
			...extraHeaders
		}
	}

	const response = await fetch(path, {
		headers: headersInit,
	});

	// If the status is 401 (Unauthorized), return null
	if (response.status === 401) {
		console.warn('Unauthorized', path);
		return null;
	}

	// If the response is not ok and it's not 401, throw an error
	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	return await response.json();
}

export async function serverPutCall<T, R>(path: string, extraHeaders: HeadersInit | null, body: T): Promise<R | null> {
	let headersInit: HeadersInit = {
		'Content-Type': 'application/json'
	}
	// append headers
	if (extraHeaders) {
		headersInit = {
			...headersInit,
			...extraHeaders
		}
	}

	const response = await fetch(path, {
		method: 'PUT',
		headers: headersInit,
		body: JSON.stringify(body),
	});

	// If the status is 401 (Unauthorized), return null
	if (response.status === 401) {
		console.warn('Unauthorized', path);
		return null;
	}

	// If the response is not ok and it's not 401, throw an error
	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	return await response.json();
}
