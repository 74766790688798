
import "@/theme/global.styles.scss";
import type { AppProps } from 'next/app';
import {GetReportPageProps, GetReportSdk} from "@getreport/next";
import {pluginInfo} from "@/config/pluginInfo";
import {joyTheme} from "@/theme/theme";
import SupportReportAuthenticate from "@/common/SupportReportAuthenticate";


const MyApp = ({ Component, pageProps }: AppProps<GetReportPageProps>) => {

	return (
		<GetReportSdk theme={joyTheme} pluginInfo={pluginInfo} pageProps={pageProps}>
			<SupportReportAuthenticate>
				<Component {...pageProps} />
			</SupportReportAuthenticate>
		</GetReportSdk>
	);
};

export default MyApp;
