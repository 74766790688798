import {FC, ReactNode, useEffect, useState} from "react";
import {getAtlassianSdk} from "./atlassianFrontendSdk";
import {PluginUserAuthResult} from "@getreport/common";
import {authServerAtlassianAuthenticate, IAuthServerPluginInfo} from "../authServer";
import GetReportPreloader from "../universal/ui/GetReportPreloader";
import {ErrorMessage, InViewport, useAuthResult, usePluginContext, usePluginInfo} from "../universal";


export type Authenticator = (pluginInfo? : IAuthServerPluginInfo) => Promise<PluginUserAuthResult | null>;
interface AtlassianAuthenticateProps {
  children: ReactNode;
  authenticator?: Authenticator;
  pluginInfo? : IAuthServerPluginInfo;
}

const defaultAuthenticator = async (pluginInfo? : IAuthServerPluginInfo) => {
  const sdk = getAtlassianSdk();
  const token = await sdk.context.getToken();
  const context = await sdk.context.getContext();

  return await authServerAtlassianAuthenticate(token, context.license, pluginInfo);
}


const AtlassianAuthenticate : FC<AtlassianAuthenticateProps> = ({children, authenticator, pluginInfo}) => {

  const pluginDetails = usePluginInfo();

  const [authFinished, setAuthFinished] = useState(false);
  const [authResult, setAuthResult] = useState<PluginUserAuthResult | null>(null);

  const existingAuthResult = useAuthResult();
  const context = usePluginContext();


  useEffect(() => {
    if (existingAuthResult) {
      return;
    }

    if (!authenticator) {
      authenticator = defaultAuthenticator;
    }

    authenticator(pluginInfo).then((authResult) => {
      setAuthResult(authResult);
      setAuthFinished(true);
      if (authResult) {
        context?.setAuthResult(authResult);
      }
    }).catch((err) => {
      console.error(err);
      setAuthFinished(true);
    });
  }, [existingAuthResult]);

  if (existingAuthResult) {
    return (<>{children}</>);
  }

  if (!authFinished) {
    return (<GetReportPreloader/>);
  }

  if (!authResult) {
    return (<InViewport viewportSize={{}}>
      <ErrorMessage error={{
        message: "Unfortunately, the server is unavailable. Please try again later.",
        link: pluginDetails?.helpLink
      }}/>
    </InViewport>)
  }

  if (!authResult.licenseInfo.canUse) {
    return (<InViewport viewportSize={{}}>
      <ErrorMessage error={{
        message: "The license has expired. Contact your administrator to renew the license.",
        link: pluginDetails?.helpLink
      }}/>
    </InViewport>)
  }

  return (<>{children}</>);
}

export default AtlassianAuthenticate;
