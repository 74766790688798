import Spinner from "@atlaskit/spinner"
import {Box} from "@mui/joy"

const GetReportPreloader = () => {
	return (
		<Box sx={{display: "grid", placeItems: "center", height: 100, width: "100%"}}>
			<Spinner interactionName="load" size={"large"}/>
		</Box>
	)
}

export default GetReportPreloader
