import {uiPluginSdkJira, uiPluginSdkConfluence} from "@getreport/next/atlassian";
import {IPluginSdk, PluginMarketplace, PluginPlatform} from "@getreport/common";
import {uiPluginSdkMonday} from "@getreport/next/monday";

const ModeJira = "jira";
const ModeConfluence = "confluence";
const ModeMonday = "monday";



export const getPluginMarketplace = () : PluginMarketplace => {
  let platform = getPluginPlatform();
  if (platform == PluginPlatform.Jira || platform == PluginPlatform.Confluence) {
    return PluginMarketplace.Atlassian;
  }
  if (platform == PluginPlatform.Monday) {
    return PluginMarketplace.Monday;
  }

  throw new Error("Unknown plugin marketplace");
}

export const getPluginPlatform = () : PluginPlatform => {
  let mode = process.env.NEXT_PUBLIC_PLUGIN_MODE;
  if (mode == ModeJira) {
    return PluginPlatform.Jira;
  }
  if (mode == ModeConfluence) {
    return PluginPlatform.Confluence;
  }
  if (mode == ModeMonday) {
    return PluginPlatform.Monday;
  }

  return PluginPlatform.Unknown;
}



export const getPluginSdk = () : IPluginSdk => {
  let platform = getPluginPlatform();
  if (platform == PluginPlatform.Jira) {
    return uiPluginSdkJira;
  } else if (platform == PluginPlatform.Confluence) {
    return uiPluginSdkConfluence;
  } else if (platform == PluginPlatform.Monday) {
    return uiPluginSdkMonday;
  }
  throw new Error("Unknown plugin platform");
}
