import {CssVarsThemeOptions} from "@mui/joy/styles/extendTheme";
import React, {ReactNode} from "react";

export enum PluginPlatform {
  Unknown = 0,
  Jira = 1,
  Confluence = 2,
  Monday = 3,
}

export enum PluginMarketplace {
  Atlassian = 0,
  Monday = 1,
  Salesforce = 2,
}

export interface ViewportSize {
  width?: number;
  height?: number;
}

export interface IPluginSdk {
  platform: PluginPlatform;
  marketplace: PluginMarketplace;
  getScript(): string;
  setViewportSize({width, height} : ViewportSize): void;
  theme: CssVarsThemeOptions;
  startupComponent?: () => React.JSX.Element;
  onStartup?: () => void;
}

export enum LicenseTierLevel {
  Trial = 0,
  BasicLicense = 1,
  PremiumEnterpriseLicense = 2,
  PremiumLicense = 3,
  DebugLicense = 100,
  Uninstalled = 50
}

/**
 * Sample:
 * 2023-07-14T18:05:32.596997Z
 */
export type Iso8601Date = `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`;

