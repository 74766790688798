import {PluginError} from "@getreport/common";
import {getHowToInstallLink, getHowToUseLink} from "@/config/pluginInfo";

export const errorUseOnlyAsJiraWidget : PluginError = {
  message: "This page can be used only as Jira Dashboard Widget",
  link: {
    url: getHowToUseLink(),
    title: "How to add widget in Jira Dashboard"
  }
};

export const errorUseOnlyAsConfluenceMacro : PluginError = {
  message: "This page can be used only as Confluence Macro",
  link: {
    url: getHowToUseLink(),
    title: "How to use"
  }
};

export const errorNoLicense : PluginError = {
  message: "The license has expired. Contact your administrator to renew the license.",
  link: {
    url: getHowToInstallLink(),
    title: "How to install"
  }
}

export const errorServerUnavailable : PluginError = {
  message: "Unfortunately, the server is unavailable. Please try again later.",
  link: {
    url: ".",
    target: "_self",
    title: "Reload"
  }
}

export const errorAuthorization : PluginError = {
	message: "You are not authorized. Please check your credentials or permissions.",
	link: {
		url: ".",
		target: "_self",
		title: "Reload"
	}
}


export const setTheTimeZone: string = "Set the time zone"
export const addLocation: string = "Add Location"
export const editLocation: string = "Edit Location"
export const cityName: string = "City name"
export const enterCity: string = "Enter city"
export const noTimeZonesAvailable: string = "No time zones available"
export const save: string = "Save"
export const cancel: string = "Cancel"
export const resetTime: string = "Reset to current time"
export const timeFormatTooltip12h: string = "12-hour (am/pm)"
export const timeFormatTooltip24h: string = "24-hour (13:00)"
export const emptyClockBoardText: string = "Press Edit button on the bottom to set time"
export const totalStoryPoints: string = "Total Story Points"
export const totalEstimated: string = "Total Estimated:"
export const totalRemaining: string = "Total Remaining:"
export const totalLogged: string = "Total Logged:"
export const noTimeLogged: string = "No time logged"
export const noneText: string = "None"
export const selectBoard: string = "Select board"

export const timeTracking = {
	title: "Time tracking",
	tooltip: "Shows total logged time and estimates across all child issues"
}

export const totalProgress = {
	title: "Total progress",
	tooltip: "Shows total progress of child issues based on story points"
}

export const storyPointsTooltipForDone = (storyPointsCount: number, totalStoryPointsCount: number): string => {
	return `Done: ${storyPointsCount} of ${totalStoryPointsCount} story points`;
};

export const storyPointsTooltipForInProgress = (storyPointsCount: number, totalStoryPointsCount: number): string => {
	return `In Progress: ${storyPointsCount} of ${totalStoryPointsCount} story points`;
};

export const storyPointsTooltipForTodo = (storyPointsCount: number, totalStoryPointsCount: number): string => {
	return `To Do: ${storyPointsCount} of ${totalStoryPointsCount} story points`;
};

