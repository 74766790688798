import {
	IMondayContext,
	IMondaySdk,
	IPluginSdk,
	MondaySessionToken,
	MondayUserDetails,
	PluginMarketplace,
	PluginPlatform,
	PluginUserAuthResult
} from "@getreport/common";
import {frontendAuthServerMondaySeamlessAuth} from "@getreport/next/authServer";
import {debugLog} from "../universal";
import {mondayJoyTheme} from "./theme";
import {subscribeToMondayEvents} from "./mondayEvents";


export const getMonday = () : IMondaySdk => {
  return window.mondaySdk();
}

type MondayUserDetailsApiResponse = {
  accountId: number,
  data: {
    me: MondayUserDetails
  }
}

export const getMondayCurrentUserInfo = async () : Promise<MondayUserDetails | undefined> => {
  try {
    const monday = getMonday();
    const result = await monday.api<MondayUserDetailsApiResponse>(`
                query {
                    me {
                        name
                        photo_thumb
                        email
                    }
                }`);
    return result?.data?.me;
  } catch {
    return undefined;
  }
}

export const getMondayContext = async() : Promise<IMondayContext> => {
  const monday = getMonday();
  const context = await monday.get("context");
  return context;
}

export const setMondaySettings = async <T extends object>(data: T) => {
	const monday = getMonday();
	const updatedSettings = await monday.set("settings", data)
	return updatedSettings
}

export const getMondaySessionToken = async() : Promise<MondaySessionToken> => {
  const monday = getMonday();
  const token = await monday.get("sessionToken");
  return token;
}

export const mondayAuthenticateOnClient = async (): Promise<PluginUserAuthResult | null> => {
  const [userDetails, token] = await Promise.all([
    getMondayCurrentUserInfo(),
    getMondaySessionToken()
  ]);

  return frontendAuthServerMondaySeamlessAuth({
    userDetails: userDetails,
    mondayAccessToken: token.data
  });
}

function mondaySetViewportSize({width, height} : {width?: number, height?: number}) {
  debugLog("Monday SetViewport not supported");
}


function onStartupMonday() {
	subscribeToMondayEvents();
}

export const uiPluginSdkMonday : IPluginSdk = {
  platform: PluginPlatform.Monday,
  marketplace: PluginMarketplace.Monday,
  getScript: () => {
    return "https://cdn.jsdelivr.net/npm/monday-sdk-js/dist/main.js";
  },
  setViewportSize: mondaySetViewportSize,
  theme: mondayJoyTheme,
	onStartup: onStartupMonday
}
