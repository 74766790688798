import {
  MondaySeamlessAuthRequest,
  PluginUserAuthResult,
  serverPostCall
} from "@getreport/common";

import {publicEndpointGetCall, publicEndpointPostCall} from "./serverCalls";

const authServerUrl : string = process.env.NEXT_PUBLIC_AUTH_SERVER_URL;
export interface IAuthServerPluginInfo {
  pluginId: string;
  publicApiKey: string;
}


export const frontendAuthServerMondaySeamlessAuth = async (options: MondaySeamlessAuthRequest) : Promise<PluginUserAuthResult | null> => {
  try {
    return await authServerPostCall<MondaySeamlessAuthRequest, PluginUserAuthResult>(authServerUrl + '/public/monday/seamless-authenticate', options);
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const authServerAtlassianAuthenticate = async (jwtToken: string, lic: string, pluginInfo?: IAuthServerPluginInfo) : Promise<PluginUserAuthResult | null> => {
  try {

    let searchParams = new URLSearchParams();
    searchParams.set('atlassianAuthToken', jwtToken);
    searchParams.set('license', lic == "active" ? "2" : "1");

    let baseUrl = authServerUrl + '/public/atlassian/authenticate';
    let fullUrl = baseUrl + "?" + searchParams.toString();

    return await authServerGetCall<PluginUserAuthResult>(fullUrl, pluginInfo);
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function authServerPostCall<T, R>(path: string, body: T, pluginInfo?: IAuthServerPluginInfo): Promise<R | null> {

  return await publicEndpointPostCall<T, R>(path, body, {}, pluginInfo);
}

async function authServerGetCall<R>(path: string, pluginInfo?: IAuthServerPluginInfo): Promise<R | null> {
    return await publicEndpointGetCall<R>(path, pluginInfo);
}

