import {GetReportPluginInfo, PluginPlatform} from "@getreport/common";

import Logo from "@/assets/images/logo.svg";
import {getPluginPlatform} from "@getreport/next";

const linkHowToInstallInJira = "https://getreport.ai/atlassian/world-clock/#installation";
const linkHowToInstallInConfluence = "https://getreport.ai/atlassian/world-clock-confluence/#installation";

const linkHowToUseInJira = "https://getreport.ai/atlassian/world-clock/#how-to-use";
const linkHowToUseInConfluence = "https://getreport.ai/atlassian/world-clock-confluence/#how-to-use";

const currentPlatform = getPluginPlatform();

export function getHowToInstallLink() {
  if (currentPlatform == PluginPlatform.Jira) {
    return linkHowToInstallInJira;
  } else if (currentPlatform == PluginPlatform.Confluence) {
    return linkHowToInstallInConfluence;
  }
  return linkHowToInstallInJira;
}

export function getHowToUseLink() {
  if (currentPlatform == PluginPlatform.Jira) {
    return linkHowToUseInJira;
  } else if (currentPlatform == PluginPlatform.Confluence) {
    return linkHowToUseInConfluence;
  }
  return linkHowToUseInJira;
}

export const pluginInfo : GetReportPluginInfo = {
  name: "Support Report",
  logoSquareUrl: Logo,
  helpLink: {
    url: getHowToUseLink(),
    title: "Visit help center"
  },
};
