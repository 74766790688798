import { useContext, useEffect } from 'react';
import { EventBusContext } from '@getreport/next/utilities/eventBusContext';

export const useEventBusSubscription = <T extends unknown>(eventName: string, eventHandler: (event: T) => void) => {
  const eventBus = useContext(EventBusContext);

  useEffect(() => {
    eventBus.on(eventName, eventHandler);

    // Unsubscribe from the event when the component unmounts
    return () => {
      eventBus.off(eventName, eventHandler);
    };
  }, [eventBus, eventName, eventHandler]);
};
