import React from 'react';

const AtlassianStartupComponent = () => {
	const code = ` 
    if (window.AP && window.AP.theming && typeof window.AP.theming.initializeTheming === 'function') {
      window.AP.theming.initializeTheming();
    } else {
      console.error('Error initializing theming: AP.theming.initializeTheming is not available.');
    }
  `;

  return (
    <script
      dangerouslySetInnerHTML={{ __html: code }}
    />
  );
}

export default AtlassianStartupComponent;
