import {AtlassianAuthenticate} from "@getreport/next/atlassian";
import {pluginChildIssues} from "@/config";
import {FC, ReactNode} from "react";

interface SupportReportAuthenticateProps {
  children: ReactNode
}

const SupportReportAuthenticate : FC<SupportReportAuthenticateProps> = ({children}) => {
  return (<AtlassianAuthenticate pluginInfo={pluginChildIssues.authServer}>
    {children}
  </AtlassianAuthenticate>)
}

export default SupportReportAuthenticate
