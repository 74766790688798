import {useHasMounted} from "../../hooks";
import React, {ReactNode} from "react";

type ClientOnlyRenderProps = {
  children: ReactNode;
};

const ClientOnlyRender: React.FC<ClientOnlyRenderProps> = ({ children }) => {
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  return children;
};

export default ClientOnlyRender;
