class EventBus {
  // Random value

  id: string = Math.random().toString(36).substring(2, 9);
  listeners: { [key: string]: ((...args: any[]) => void)[] } = {};

  on(event: string, callback: (...args: any[]) => void) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }
    this.listeners[event].push(callback);
  }

  off(event: string, callback: (...args: any[]) => void) {
    this.listeners[event] = this.listeners[event]?.filter(listener => listener !== callback);
  }

  emit(event: string, ...args: any[]) {
    this.listeners[event]?.forEach(listener => listener(...args));
  }
}

export default EventBus;
