import {useEffect, useState} from "react";
import {useColorScheme} from "@mui/joy";

type TColorMode = "light" | "dark" | null;

export const useColorMode = (): TColorMode => {
	const [colorMode, setColorMode] = useState<TColorMode>(null);
	const {setMode} = useColorScheme();

	useEffect(() => {
		const initializeTheming = () => {
			const initialColorMode = document.documentElement.dataset.colorMode as TColorMode;
			setColorMode(initialColorMode);

			// Listen for changes to the data-color-mode attribute
			const observer = new MutationObserver(() => {
				const newColorMode = document.documentElement.dataset.colorMode as TColorMode;
				setColorMode(newColorMode);
			});
			observer.observe(document.documentElement, {attributes: true});
		};

		initializeTheming();
	}, []);

	useEffect(() => {
		if (colorMode) {
			setMode(colorMode);
		}
	}, [colorMode]);

	return colorMode;
};
