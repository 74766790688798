import { useState, useEffect } from 'react';

export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  // Initialize state with current window width/height
  const [size, setSize] = useState({
    width: isClient ? window.innerWidth : 100,
    height: isClient ? window.innerHeight : 100
  });

  useEffect(() => {
    if (!isClient) {
      // If we're not running in a browser environment, don't set up the resize listener
      return;
    }

    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);

  }, []); // Empty array ensures that effect is only run on mount and unmount

  return size;
}
