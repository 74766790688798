import {EventBus, useEventBus} from "../utilities";
import {IMondayContext, IMondaySdk, IMondaySettings} from "@getreport/common";
import {useEffect, useState} from "react";
import {useEventBusSubscription} from "../hooks";
import {getMonday} from "./mondayFrontendSdk";

const useWaitForMondaySdk = (callback: () => void, delay = 100) => {
	const [isMondaySdkLoaded, setMondaySdkLoaded] = useState(false);
	const [hasCalled, setHasCalled] = useState(false);

	useEffect(() => {
		if (isMondaySdkLoaded && !hasCalled) {
			callback();
			setHasCalled(true);
		} else if (!isMondaySdkLoaded) {
			if (typeof window !== 'undefined') {
				const intervalId = setInterval(() => {
					if (window) {
						setMondaySdkLoaded(true);
						clearInterval(intervalId);
					}
				}, delay);
				// Cleanup function to clear the interval on unmount
				return () => clearInterval(intervalId);
			}
		}
	});

	// expose the setMondaySdkLoaded function if you want to allow manually setting the mondaySdk loaded state
	// return setMondaySdkLoaded;
};

export function subscribeToMondayEvents() {
	const eventBus = useEventBus();

	const [mondayReady, setMondayReady] = useState<boolean>(false);
	const [hasRun, setHasRun] = useState<boolean>(false);


	useWaitForMondaySdk(() => {
		setMondayReady(true);
	});


	useEffect(() => {
		if (mondayReady && !hasRun) {
			const monday = getMonday();
			if (monday) {
				subscribeMondaySdk(eventBus, monday);
			}
			setHasRun(true);
		}
	}, [mondayReady]);
}

export const eventMondayContextRefresh = "mondayContextRefresh";
export const eventMondaySettingsRefresh = "mondaySettingsRefresh";
export const eventMondaySettingsGet = "mondaySettingsGet";

function subscribeMondaySdk(eventBus: EventBus, api: IMondaySdk) {
	api.listen("context", (res) => {
		eventBus.emit(eventMondayContextRefresh, res);
	});
	api.listen("settings", (res) => {
		eventBus.emit(eventMondaySettingsRefresh, res)
	})
	api.get('settings').then((res) => {
		eventBus.emit(eventMondaySettingsGet, res)
	});
}

export function useMondayContextRefresh(callback: (res: IMondayContext | null) => void) {
	useEventBusSubscription(eventMondayContextRefresh, callback);
}

export function useMondaySettingsRefresh<T extends object>(callback: (res: IMondaySettings<T> | null) => void) {
	useEventBusSubscription(eventMondaySettingsRefresh, callback);
}
export function useMondaySettingsGet<T extends object>(callback: (res: IMondaySettings<T> | null) => void) {
	useEventBusSubscription(eventMondaySettingsGet, callback);
}
