import React, {ReactNode} from "react";
import {GetReportPluginInfo, PluginUserAuthResult} from "@getreport/common";
import GetReportErrorBoundary from "../ui/errors/GetReportErrorBoundary";
import {CssVarsProvider, Theme} from "@mui/joy";
import {EventBusContext, eventBus} from "@getreport/next/utilities";
import PluginStartupRunner from "./PluginStartupRunner";

interface GetReportSdkProps {
	// jwtDecoded: JWTDecoded | null;
	// dashboardItemId: string;
	// dashboardId: string;
	pluginInfo: GetReportPluginInfo,
	children: ReactNode,
	theme: Theme,
	pageProps: GetReportPageProps
}

interface GetReportPluginContext {
	pluginInfo: GetReportPluginInfo,
	authResult?: PluginUserAuthResult
	setAuthResult: React.Dispatch<React.SetStateAction<PluginUserAuthResult | undefined>>
}

const GetReportPluginInfoContext = React.createContext<GetReportPluginContext | null>(null);

export interface GetReportPageProps {
	authResult?: PluginUserAuthResult;
}


const GetReportSdk: React.FC<GetReportSdkProps> = ({children, pluginInfo, theme, pageProps}) => {
	const [authResult, setAuthResult] = React.useState<PluginUserAuthResult | undefined>(pageProps.authResult);

	return (
		<GetReportPluginInfoContext.Provider value={{pluginInfo, authResult, setAuthResult}}>
			<EventBusContext.Provider value={eventBus}>
				<CssVarsProvider theme={theme}>
					<GetReportErrorBoundary>
						<PluginStartupRunner>
							{children}
						</PluginStartupRunner>
					</GetReportErrorBoundary>
				</CssVarsProvider>
			</EventBusContext.Provider>
		</GetReportPluginInfoContext.Provider>
	);
};

export const useGetReportPluginContext = () => {
	const context = React.useContext(GetReportPluginInfoContext);
	if (!context) {
		throw new Error('useGetReportPluginContext must have context set');
	}
	return context;
};

export const useAuthResult = () => {
	return React.useContext(GetReportPluginInfoContext)?.authResult;
}

export const usePluginContext = () => {
	return React.useContext(GetReportPluginInfoContext);
}

export const usePluginInfo = (): GetReportPluginInfo | undefined => {
	return React.useContext(GetReportPluginInfoContext)?.pluginInfo;
};

export const useCurrentUser = () => {
	return React.useContext(GetReportPluginInfoContext)?.authResult?.pluginUser;
};

export const useUserToken = () => {
	return React.useContext(GetReportPluginInfoContext)?.authResult?.userToken;
};

export const useLicenseInformation = () => {
	return React.useContext(GetReportPluginInfoContext)?.authResult?.licenseInfo;
};

export default GetReportSdk;
