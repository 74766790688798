import merge from 'lodash.merge';
import {CssVarsThemeOptions} from "@mui/joy/styles/extendTheme";

export const overrideTheme = (baseTheme: CssVarsThemeOptions, overrideTheme: Partial<CssVarsThemeOptions>): CssVarsThemeOptions => {
  return merge({}, baseTheme, overrideTheme);
};


export const pluginsDefaultTheme : CssVarsThemeOptions = {

}
