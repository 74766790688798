import {IAtlassianSdk, IPluginSdk, PluginMarketplace, PluginPlatform} from "@getreport/common";
import {atlassianJoyTheme} from "./theme";
import AtlassianStartupComponent from "../monday/AtlassianStartupComponent";
import {subscribeToAtlassianEvents} from "./atlassianEvents";
import {debugLog} from "../universal";

const atlScript = "https://connect-cdn.atl-paas.net/all.js";

export function getAtlassianSdk() : IAtlassianSdk {
  return window.AP;
}

export function atlassianSetViewportSize({width, height} : {width?: number, height?: number}) {
  debugLog("atlassianSetViewportSize", {width, height});
  const AP = getAtlassianSdk();
  AP.resize(width?.toString() ?? null, height?.toString() ?? null);
}

function onStartupJira() {
  subscribeToAtlassianEvents(PluginPlatform.Jira);
}

function onStartupConfluence() {
  subscribeToAtlassianEvents(PluginPlatform.Confluence);
}

export const uiPluginSdkJira : IPluginSdk = {
  platform: PluginPlatform.Jira,
  marketplace: PluginMarketplace.Atlassian,
  getScript: () => {
    return atlScript;
  },
  setViewportSize: atlassianSetViewportSize,
  theme: atlassianJoyTheme,
  startupComponent: AtlassianStartupComponent,
  onStartup: onStartupJira
}

export const uiPluginSdkConfluence : IPluginSdk = {
  platform: PluginPlatform.Confluence,
  marketplace: PluginMarketplace.Atlassian,
  getScript: () => {
    return atlScript;
  },
  setViewportSize: atlassianSetViewportSize,
  theme: atlassianJoyTheme,
  startupComponent: AtlassianStartupComponent,
  onStartup: onStartupConfluence
}
