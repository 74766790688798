import {EventBus, useEventBus} from "../utilities";
import {DashboardItemRefreshOrigin, IJiraDashboardItemApi, PluginPlatform} from "@getreport/common";
import {getAtlassianSdk} from "./atlassianFrontendSdk";
import {useEffect, useState} from "react";
import {useEventBusSubscription} from "../hooks";

const useWaitForAP = (callback: () => void, delay = 100) => {
  const [isAPLoaded, setAPLoaded] = useState(false);
  const [hasCalled, setHasCalled] = useState(false);

  useEffect(() => {
    if (isAPLoaded && !hasCalled) {
      callback();
      setHasCalled(true);
    } else if (!isAPLoaded) {
      if (typeof window !== 'undefined') {
        const intervalId = setInterval(() => {
          if (window && window.AP) {
            setAPLoaded(true);
            clearInterval(intervalId);
          }
        }, delay);
        // Cleanup function to clear the interval on unmount
        return () => clearInterval(intervalId);
      }
    }
  });

  // expose the setAPLoaded function if you want to allow manually setting the AP loaded state
  // return setAPLoaded;
};



export function subscribeToAtlassianEvents(platform: PluginPlatform) {
  const eventBus = useEventBus();

  const [apReady, setApReady] = useState(false);
  const [hasRun, setHasRun] = useState(false);


  useWaitForAP(() => {
    setApReady(true);
  });


  useEffect(() => {
    if (apReady && !hasRun) {
      const AP = getAtlassianSdk();
      if (AP.jira?.DashboardItem) {
        subscribeDashboardItem(eventBus, AP.jira.DashboardItem);
      }

      setHasRun(true)
    }
  }, [apReady]);
}


/// Dashboard Items

export const eventJiraOnDashboardItemEdit = "jira.onDashboardItemEdit";
export const eventJiraOnDashboardItemRefresh = "jira.onDashboardItemRefresh";

function subscribeDashboardItem(eventBus : EventBus, api: IJiraDashboardItemApi) {
  api.onDashboardItemEdit(() => {
    eventBus.emit(eventJiraOnDashboardItemEdit, {});
  });

  api.onDashboardItemRefresh((origin) => {
    eventBus.emit(eventJiraOnDashboardItemRefresh, {origin});
  });
}

/**
 * Not working in jira for some reason
 * @param callback
 */
export function useJiraOnDashboardItemRefresh(callback: ({origin}: {origin: DashboardItemRefreshOrigin}) => void) {
  useEventBusSubscription(eventJiraOnDashboardItemRefresh, callback);
}

export function useJiraOnDashboardItemEdit(callback: () => void) {
  useEventBusSubscription(eventJiraOnDashboardItemEdit, callback);
}
