import { serverGetCall, serverPostCall, serverPutCall } from '@getreport/common';
import { IAuthServerPluginInfo } from './frontendAuthServerClient';
import { useUserToken } from '../universal';
import { useEffect, useState } from 'react';

const publicToken: string = process.env.NEXT_PUBLIC_AUTH_SERVER_PUBLIC_API_KEY;

export async function publicEndpointPostCall<T, R>(
  path: string,
  body: T,
  extraHeaders: HeadersInit = {},
  pluginInfo?: IAuthServerPluginInfo
): Promise<R | null> {
  return await serverPostCall<T, R>(
    path,
    {
      PublicApiToken: pluginInfo?.publicApiKey || publicToken,
      ...extraHeaders,
    },
    body
  );
}

// Get call
export async function publicEndpointGetCall<R>(path: string, pluginInfo?: IAuthServerPluginInfo): Promise<R | null> {
  return await serverGetCall<R>(path, {
    PublicApiToken: pluginInfo?.publicApiKey || publicToken,
  });
}

export async function userEndpointGetCall<R>(path: string, token: string): Promise<R | null> {
  return await serverGetCall<R>(path, {
    ApiUserToken: token,
  });
}

export async function userEndpointGetTicketStatus<R>(path: string, token: string): Promise<R | null> {
  return await serverGetCall<R>(path, {
    ApiUserToken: token,
  });
}

export async function userEndpointPutCall<T, R>(path: string, token: string, body: T): Promise<R | null> {
  return await serverPutCall<T, R>(
    path,
    {
      ApiUserToken: token,
    },
    body
  );
}

export async function userEndpointPostCall<T, R>(path: string, token: string, body: T): Promise<R | null> {
  return await serverPostCall<T, R>(
    path,
    {
      ApiUserToken: token,
    },
    body
  );
}

export type HookApiResponse<T> = {
  loading: boolean;
  error: string | null;
  retry: () => void;
  result: T | null;
};

export type ApiDependencies = {
  isLocalStoragePrepared?: boolean;
  isValidDate?: boolean;
};

export const useGenericApiCall = <T>(apiFunc: (token: string) => Promise<T | null>, deps: ApiDependencies = {}): HookApiResponse<T> => {
  const userToken = useUserToken();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [result, setResult] = useState<T | null>(null);

  const sendApiCall = async () => {
    setLoading(true);
    try {
      if (userToken) {
        const apiResult = await apiFunc(userToken);
        setResult(apiResult);
      } else {
        setError('User token is not available');
      }
    } catch (e) {
      console.error(e);
      setError(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  //console.log("hook values", loading, error, retryCount, result);

  useEffect(() => {
    if (Object.keys(deps)) {
      if (deps?.isLocalStoragePrepared && deps?.isValidDate) {
        sendApiCall().catch(console.error);
      }
    }
    sendApiCall().catch(console.error);
  }, [retryCount]);

  return {
    loading,
    error,
    retry: () => setRetryCount(retryCount + 1),
    result,
  };
};

export const useApiCallWithParams = <T>(
  apiFunc: (token: string, params: { issueId?: string }) => Promise<T | null>,
  params: { issueId?: string },
  deps: unknown[] = []
): HookApiResponse<T> => {
  const userToken = useUserToken();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [result, setResult] = useState<T | null>(null);

  const sendApiCall = async () => {
    setLoading(true);
    try {
      if (userToken) {
        const apiResult = await apiFunc(userToken, params);
        setResult(apiResult);
      } else {
        setError('User token is not available');
      }
    } catch (e) {
      console.error(e);
      setError(`Error: ${e}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sendApiCall().catch(console.error);
  }, [retryCount, ...deps]);

  return {
    loading,
    error,
    retry: () => setRetryCount(retryCount + 1),
    result,
  };
};
