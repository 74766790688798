import {ViewportSize} from "@getreport/common";
import {ILink} from "@/types/general";
import {getHowToUseLink} from "@/config/pluginInfo";
import {IAuthServerPluginInfo} from "@getreport/next/authServer";

export const defaultJiraHeight = 200;

export const storyPointsBacklogGadgetName = "Story points";

export function getJiraWidgetViewport(): ViewportSize {
	return {
		height: defaultJiraHeight
	};
}


export const atlassianLinks: ILink[] = [
	{
		name: "How to use",
		url: getHowToUseLink()
	},
	{
		name: "Suggest a feature",
		url: "https://getreportsupport.atlassian.net/servicedesk/customer/portal/2/group/2/create/14"
	}
];

export const storyPointsLocalstorageKey = "story-points-field-key";

export const childIssuesLink: ILink = {
	name: "Suggest a feature",
	url: "https://getreportsupport.atlassian.net/servicedesk/customer/portal/2/group/2/create/14"
};

export interface IJiraSimplePluginInfo {
	authServer: IAuthServerPluginInfo;
}

export const pluginChildIssues : IJiraSimplePluginInfo = {
	authServer: {
		pluginId: "getreport.support.report",
		publicApiKey: process.env.NEXT_PUBLIC_AUTH_SUPPORT_REPORT as string
	}
}
