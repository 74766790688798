import React from 'react';
import EventBus  from './EventBus';

export const eventBus = new EventBus();


// Create a context for the EventBus
export const EventBusContext = React.createContext(eventBus);

export const useEventBus = () : EventBus => {
  const bus = React.useContext(EventBusContext);
  return bus;
}
