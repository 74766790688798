var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f5bc91a88240b4848dac3f689544e67bf31a5df7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
	dsn: "https://324621d7a9d97ba666eb7864f93c7cd3@o4505082012041216.ingest.sentry.io/4506275838033920",
	integrations: [new Sentry.Replay()],
	tracesSampleRate: 1.0,

	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});
