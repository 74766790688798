import {getPluginSdk} from "@getreport/next";
import {extendTheme} from "@mui/joy";


const pluginTheme = getPluginSdk().theme;

declare module '@mui/joy/styles' {
	interface Palette {
		background: {
			am1: string;
			am2: string;
			am3: string;
			pm1: string;
			pm2: string;
			pm3: string;
			default: string;
		};
		backgroundColor: {
			surfaceOverlay: string;
			todoProgressbar: string;
			sheetTable: string;
		};
		color: {
			am1: string;
			am2: string;
			am3: string;
			pm1: string;
			pm2: string;
			pm3: string;
			default: string;
		};
		atlassianColors: {
			iconSubtle: string;
			iconDisabled: string;
			borderNeutral: string;
			borderInformation: string;
			borderWarning: string;
			text: string;
			backgroundInformation: string;
			textInformation: string;
			backgroundNeutral: string;
			textSubtle: string;
			backgroundSuccess: string;
			textSuccess: string;
			backgroundInputHovered: string;
		};
	}

	interface TypographySystemOverrides {
		jiraIssueH2: true;
		nextLink: true;
	}
}

export const joyTheme = extendTheme({
	...pluginTheme,
	colorSchemes: {
		light: {
			palette: {
				mode: "light",
				background: {
					am1: "linear-gradient(180deg, #485A9B 0%, #293561 100%)",
					am2: "linear-gradient(180deg, #6090C7 0%, #366090 100%)",
					am3: "linear-gradient(180deg, #FFEED1 0%, #FFE0AA 100%)",
					pm1: "linear-gradient(180deg, #FFE0AA 0%, #FFC866 0.01%, #FEBB45 100%)",
					pm2: "linear-gradient(180deg, #D9B5D8 0%, #9E7B9D 100%)",
					pm3: "linear-gradient(180deg, #485A9B 0%, #293561 100%)",
					default: "#F7F8F9"
				},
				backgroundColor: {
					surfaceOverlay: "#FFFFFF",
					todoProgressbar: "rgba(241,242,244,255)",
					sheetTable: "#FFFFFF"
				},
				color: {
					am1: "#FFF",
					am2: "#FFF",
					am3: "#172B4D",
					pm1: "#172B4D",
					pm2: "#FFF",
					pm3: "#FFF",
					default: "#626F86"
				},
				atlassianColors: {
					iconSubtle: "#626F86",
					iconDisabled: "#091E424F",
					borderNeutral: "#A6C5E229",
					borderInformation: "#1D7AFC",
					borderWarning: "#D97008",
					text: "#172B4D",
					backgroundInformation: "#E9F2FF",
					textInformation: "#0055CC",
					backgroundNeutral: "#091E420F",
					textSubtle: "#44546F",
					backgroundSuccess: "#DFFCF0",
					textSuccess: "#216E4E",
					backgroundInputHovered: "#F7F8F9"
				}
			}
		},
		dark: {
			palette: {
				mode: "dark",
				background: {
					am1: "linear-gradient(180deg, #012459 0%, #001322 100%)",
					am2: "linear-gradient(180deg, #74D4CC 0%, #1386A6 100%)",
					am3: "linear-gradient(180deg, #FDC352 0%, #E8ED92 100%)",
					pm1: "linear-gradient(180deg, #FDA65A 0%, #FFE467 100%)",
					pm2: "linear-gradient(180deg, #D1628B 0%, #5B2C83 100%)",
					pm3: "linear-gradient(180deg, #040B3C 0%, #233072 100%)",
					default: "#F7F8F9"
				},
				backgroundColor: {
					surfaceOverlay: "#22272B",
					todoProgressbar: "rgba(241,242,244,0.5)",
					sheetTable: "#1D2125"
				},
				color: {
					am1: "#FFF",
					am2: "#FFF",
					am3: "#172B4D",
					pm1: "#172B4D",
					pm2: "#FFF",
					pm3: "#FFF",
					default: "#626F86"
				},
				atlassianColors: {
					iconSubtle: "#8C9BAB",
					iconDisabled: "#BFDBF847",
					borderNeutral: "#A6C5E229",
					borderInformation: "#388BFF",
					borderWarning: "#CF9F02",
					text: "#B6C2CF",
					backgroundInformation: "#092957",
					textInformation: "#85B8FF",
					backgroundNeutral: "#A1BDD914",
					textSubtle: "#9FADBC",
					backgroundSuccess: "#143C2B",
					textSuccess: "#7EE2B8",
					backgroundInputHovered: "#282E33"
				}
			}
		}
	},
	typography: {
		jiraIssueH2: {
			fontSize: "12px",
			fontStyle: "inherit",
			fontWeight: 600,
			lineHeight: 1.3333333333333333,
			letterSpacing: "-0.01em"
		},
		nextLink: {
			fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			fontSize: "14px",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: 1.4285714286
		}
	},
	components: {
		JoyTypography: {
			defaultProps: {
				levelMapping: {
					jiraIssueH2: "h2",
					nextLink: "a"
				}
			}
		}
	}
});
