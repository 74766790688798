import Button from "@atlaskit/button";

const AtlassianPluginsUiKit = () => {
  return (
    <>
      <h1>Header 1</h1>
      <h2>Header 2</h2>
      <h3>Header 3</h3>
      <h4>Header 4</h4>
      <h5>Header 5</h5>
      <p>Paragraph</p>
      <Button appearance="primary">Primary button</Button>
      <Button appearance="warning">Warning button</Button>
    </>
  );
};

export default AtlassianPluginsUiKit;
