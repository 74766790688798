import React, {ReactNode} from 'react';
import {useViewportSize} from "../hooks";
import {ViewportSize} from "@getreport/common";

const InViewport = ({viewportSize, children} : {viewportSize: ViewportSize, children: ReactNode}) => {
  useViewportSize(viewportSize);

  return (
    <>
      {children}
    </>
  );
};

export default InViewport;
